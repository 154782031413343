.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /* background-color: #282c34; */
    background-color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.palavra {
    font-size: 150px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 70px;
}

.timer {
    font-size: 82px;
    font-weight: bold;
}

.placar {
    display: flex;
    flex-direction: row;
    /* background-color: white; */
}

.placar-itens {
    font-size: 42px;
    font-weight: bold;
    margin: 0 20px 0 20px;
}

.erros {
    color: red;
}

.pulos {
    color: yellow;
}

.acertos {
    color: green;
}

button {
    margin: 20px;
    padding: 5px 10px;
    font-size: 24px;
    font-weight: bold;
}

.buttons {
    margin-top: 40px;
    ;
}

button.errou {
    background-color: red;
    color: white;
}

button.pulou {
    background-color: yellow;
}

button.acertou {
    background-color: green;
    color: white;
}

.div-config {
    margin: 50px 0 20px 0;
}